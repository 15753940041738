body {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 16px;
    line-height: 2.0;
    color: #171d1b;
    @media screen and (min-width: 768px) {
        font-size: 18px;
    }
    @media screen and (min-width: 1024px) {
        font-size: 20px;
    }
}

a {
    color: #171d1b;
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}
p {
    margin: 0;
}